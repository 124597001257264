import { FormGroup, SanaForm } from 'components/objects/forms';
import { TextAreaField } from 'components/objects/forms/fields';
import { memo } from 'react';

const XmlOrderImportTestForm = () => {
    const handleSubmit = () => {
        const formElement = document.getElementById('xmlOrderImportTest');
        formElement.submit();
    };

    return(
        <SanaForm
          id={'xmlOrderImportTest'}
          name={'xmlOrderImportTest'}
          onSubmit={handleSubmit}
          method={'POST'}
          action={'/sap/import.aspx'}
        >
            <FormGroup
              fieldName={'xmlOrderRequest'}
              fieldTitle={'xmlOrderRequest'}
              fieldComponent={TextAreaField}
              maxLength={10000}
            />

            <SanaForm.SubmitRow textKey={'Submit'} />
        </SanaForm>
    );
};

export default memo(XmlOrderImportTestForm);