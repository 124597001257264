import styles from './BasketB2B.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { PromotionalText } from 'components/objects/promotionalTexts';
import { QuoteButton, CheckoutButton } from '../actionButtons';
import Actions from './Actions';
import Totals from './Totals';
import SalesAgreementControl from '../SalesAgreementControl';
import PromotionControl from '../PromotionControl';
import { connect, useSelector } from 'react-redux';
import OciCheckoutButton from '../actionButtons/OciCheckoutButton';

const Details = ({ isEmpty, isBlocked, enablePromotionCodes, canCreateQuote, canCreateOrder, salesAgreementId, editDocumentId }) => {
  const detailsTitlePlaceholder = <Placeholder className={styles.detailsTitlePlaceholder} />;
  const isPunchoutUser = useSelector(state$ => state$.user.isPunchoutUser);

  return (
    <>
      <h2 className={styles.detailsTitle}>
        <SimpleText textKey="BasketDetails" placeholder={detailsTitlePlaceholder} />
      </h2>
      <div className={styles.totalsWrapper}>
        {!isEmpty && enablePromotionCodes && <PromotionControl />}
        {!editDocumentId && <SalesAgreementControl />}
        {!isEmpty && <Totals />}
      </div>
      <div className={styles.actions}>
        <Actions isEmpty={isEmpty} />
        {canCreateQuote && !salesAgreementId && <QuoteButton disabled={isEmpty} canCreateOrder={canCreateOrder} />}
        {canCreateOrder && !isPunchoutUser && <CheckoutButton disabled={isEmpty || isBlocked} />}
        {canCreateOrder && isPunchoutUser && <OciCheckoutButton disabled={isEmpty || isBlocked} />}
      </div>
      <div className={styles.promo}>
        <PromotionalText textKey="BasketPage_B2B_ColumnFooter" />
      </div>
    </>
  );
};

Details.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool,
  enablePromotionCodes: PropTypes.bool.isRequired,
  editDocumentType: PropTypes.string,
  salesAgreementId: PropTypes.string,
  editDocumentId: PropTypes.string,
};

const mapStateToProps = ({
  basket: {
    model,
    salesAgreementInfo,
  },
  settings: {
    checkout: {
      enablePromotionCodes,
    },
  },
}) => ({
  isEmpty: !model || (!model.totalCount && !model.productLines.totalCount),
  isBlocked: model?.isBlocked,
  enablePromotionCodes,
  salesAgreementId: salesAgreementInfo?.id,
  editDocumentId: model?.editDocumentId,
});

export default connect(mapStateToProps)(Details);
