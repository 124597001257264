import { useSelector } from 'react-redux';
import { first, pluck, map, startWith } from 'rxjs/operators';

export default (routeData, state$, _dependencies) => state$.pipe(
  startWith(state$.value),
  map(s => {
    return {
      user: s.user,
      settings: s.settings,
    };
  }),
  first(s =>  s.settings.loaded && s.user.initialized),
  map(s => {
    const user = s.user;
    const homePage = user.isPunchoutUser ? s.settings.punchoutHomePage?.to : s.settings.homePage?.to;
    if (!homePage)
      return null;

    return {
      forwardTo: {
        ...homePage,
        params: { ...homePage.params, ...routeData.params },
      },
    };
  }),
);