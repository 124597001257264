import styles from './ActionButtons.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { SanaLinkButton } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import React from 'react';
import PropTypes from 'prop-types';
import { useUpdateContext } from '../updateContext';
import { routesBuilder } from 'routes';

const OciCheckoutButton = ({ disabled, className = '' }) => {
    const { writeOnlySubmit } = useUpdateContext();

    return (
        <SanaLinkButton
          textKey="OciProceedToCheckout"
          to={routesBuilder.forPunchoutCheckout}
          onClick={() => writeOnlySubmit()}
          disabled={disabled}
          className={`${btnStyles.btnBig} ${btnStyles.btnAction} ${styles.btnCheckout} ${className}`}
          placeholder={<Placeholder className={styles.btnCheckoutPlaceholder} />}
        />
    );
};

OciCheckoutButton.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default React.memo(OciCheckoutButton);