import styles from './AccountMenu.module.scss';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'components/primitives/dropdown';
import { useSelector, shallowEqual } from 'react-redux';
import IconWithText from './iconWithText';
import AccountMenuItems from './accountMenuItems';
import { useOnChange } from 'utils/hooks';

const AccountMenuBase = ({ options, isDesignerMode }) => {
  const [dropdownOptions, setDropdownOptions] = useState({ show: false });
  const { isAuthenticated, isImpersonating, shopAccountType, isPunchoutUser } = useSelector(({ user }) => ({
    isAuthenticated: user.isAuthenticated,
    isImpersonating: user.isImpersonating,
    shopAccountType: user.shopAccountType,
    isPunchoutUser: user.isPunchoutUser,
  }), shallowEqual);

  useOnChange(() => setDropdownOptions({ show: false }), [isAuthenticated, isImpersonating], false);

  return (!isPunchoutUser &&
    <div className={styles.wrapper}>
      <Dropdown
        bodyAlign="right"
        className="login-info"
        options={dropdownOptions}
        header={(
          <div className={styles.toggleContent}>
            <IconWithText
              presentation={options.togglePresentation}
              isAuthenticated={isAuthenticated}
              isImpersonating={isImpersonating}
            />
          </div>
        )}
      >
        {!isDesignerMode && (
          <div className={styles.body}>
            <AccountMenuItems
              isAuthenticated={isAuthenticated}
              isImpersonating={isImpersonating}
              shopAccountType={shopAccountType}
            />
          </div>
        )}
      </Dropdown>
    </div>
  );
};

AccountMenuBase.propTypes = {
  options: PropTypes.shape({
    togglePresentation: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(AccountMenuBase);
