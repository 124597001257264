import { SanaForm, FormGroup } from 'components/objects/forms';
import { PasswordField, TextBoxField } from 'components/objects/forms/fields';
import PropTypes from 'prop-types';
import { memo } from 'react';

const FormPunchoutLoginTestForm = ({
    userName,
    password,
    hookUrl,
    language,
}) => {

    const handleSubmit = data => {
      const formElement = document.getElementById("formPunchoutLoginTest");
      formElement.submit();
    };
  
    const initialValues = {
        userName: userName ?? 'v.kolomiichuk@ism.nl',
        password: password ?? 'abc123!',
        hook_URL: hookUrl ?? '/sap/showFormSubmit',
        language: language ?? 'EN',
    };

    return(
        <SanaForm
          id={'formPunchoutLoginTest'}
          name={'FormPunchoutLoginTest'}
          onSubmit={handleSubmit}
          method={'POST'}
          action={'/sap/login.aspx'}
          initialValues={initialValues}
        >
            <FormGroup
              fieldName={'userName'}
              fieldTitle='Username'
              fieldComponent={TextBoxField}
              maxLength={100}
            />

            <FormGroup
              fieldName='password'
              fieldTitle='Password'
              fieldComponent={PasswordField}
              maxLength={100}
            />

            <FormGroup
              fieldName='hook_URL'
              fieldTitle='HOOK URL'
              fieldComponent={TextBoxField}
              maxLength={300}
            />

            <FormGroup
              fieldName='language'
              fieldTitle='Language'
              fieldComponent={TextBoxField}
              maxLength={10}
            />
            
            <SanaForm.SubmitRow textKey='Login' />
        </SanaForm>
    );
};

FormPunchoutLoginTestForm.propTypes = {
    userName: PropTypes.string,
    password: PropTypes.string,
    hookUrl: PropTypes.string,
    language: PropTypes.string
};

export default memo(FormPunchoutLoginTestForm);