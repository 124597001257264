import styles from '../Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UseSanaButton } from 'components/primitives/buttons';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import { Placeholder } from 'components/primitives/placeholders';
import { useModal } from 'components/objects/modals';
import { addProductToWishList } from 'behavior/wishList/actions';
import { useProductContext } from '../hooks';
import { useOnChange } from 'utils/hooks';
import LoginPrompt from './LoginPrompt';
import SuccessMessage from './SuccessMessage';
import { ConfirmationModal } from 'components/objects/wishList';

const AddToWishlist = ({ abilities: [useWishlistAbility], addProductToWishList, wishList }) => {
  if(useWishlistAbility === AbilityState.NotAvailable)
    return null;
  
  const showLoginFirstPopup = !useWishlistAbility || useWishlistAbility === AbilityState.Unauthorized;

  const { product, uomId, variantId } = useProductContext();
  const { opened, toggle } = useModal();

  const addToWishlistLinkPlaceholder = <Placeholder className={styles.addToWishlistLinkPlaceholder} />;
  const addToWishList = () => showLoginFirstPopup ? toggle() : addProductToWishList(product.id, uomId, variantId);

  useOnChange(() => {
    toggle();
  }, [wishList], false);

  return (
    <>
      <UseSanaButton textKey="AddToWishList" placeholder={addToWishlistLinkPlaceholder}>
        {text => (
          <button
            className={`${linkStyles.link} ${styles.addToWishlistLink}`}
            onClick={addToWishList}
            type="button"
          >
            {text}
          </button>
        )}
      </UseSanaButton>
      <ConfirmationModal opened={opened} hide={toggle}>
        {showLoginFirstPopup ? <LoginPrompt /> : <SuccessMessage />}
      </ConfirmationModal>
    </>
  );
};

AddToWishlist.propTypes = {
  abilities: abilitiesPropType,
  className: PropTypes.string,
  addProductToWishList: PropTypes.func.isRequired,
};

const mapStateToProps = ({ wishList }) => ({ wishList });

const mapDispatchToProps = {
  addProductToWishList,
};

export default withAbilities(
  connect(mapStateToProps, mapDispatchToProps)(AddToWishlist),
  [AbilityTo.UseWishlist],
);
