import { logout } from "behavior/user";
import { from, of } from "rxjs";
import { decapitalize } from "utils/helpers";
import { postForm } from "../actions";

export default () => {
    return () => {
      return from(fetch('/sap/checkout')
        .then(response => response.json())
        .then(model => {
            

            const formOptions = {
              url: model.hookUrl,
              values: model.lines.map(line => {
                  return {
                      key: line.name,
                      value: line.value
                  }
              })
            };

            return {
                action$: of(postForm(formOptions), logout())
            };
        }));
    };
  };